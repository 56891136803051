export const BG_PRIMARY = '#F8F8F3';
export const BG_SECONDARY = '#CAD5CA';
export const BG_SECONDARY_LIGHT = '#a2aaa2';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(202 213 202 / 29%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#CAD5CA';
export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = '#a2aaa2';
export const TEXT_PRIMARY_DARK = '#333333';
export const TEXT_SECONDARY = '#fff';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#000';
